<template>
<div class="btn-group-container">
  <div class="d-flex flex-column-reverse mt-3 btn-group-wrapper" :class="`${customClasses}`">
    <button
      v-on:click="onCancel"
      class="shadow-none cancel-btn action-btn left-arrow"
      :disabled="disableBack"
      v-if="!isCarInfoPage"
    >
      {{ cancelText }}
    </button>
    <div v-if="this.$route.name === 'photo-upload'" :class="disableSkip >= 1 && !isHavingUploadIssue ? 'skip-btn-cursor' : ''" class="skip-btn-wrapper">
      <div class="skip-photo" :class="disableSkip >= 1 && !isHavingUploadIssue ? 'hide-tooltip' : ''">
        <button
          v-show="this.curStep === 2"
          v-on:click="onSkip"
          class="shadow-none skip-btn action-btn right-arrow"
          :disabled="disableSkip > 0 && !isHavingUploadIssue"
        >
          {{ skipText }}
        </button>
        <div v-show="this.curStep === 2" class="font-weight-thin" id="tooltip" ref="tooltip" v-if="!isMobile">
          After you submit, we'll send you a link to add photos.
        </div>
      </div>
    </div>
    <button
      v-on:click="onOk"
      :class="`border-0 shadow-none text-white ok-btn action-btn right-arrow ${okBtnDisable ? '' : 'disabled'}`"
      :disabled="disableNext"
    >
      <b-spinner v-if="isLoadingApi && okText === 'submit'" label="Loading..." class="btn-loading"></b-spinner>
      <template v-else>
        {{ okText }}
      </template>
    </button>
  </div>
</div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "BtnGroup",
  props: {
    okText: {
      type: String,
      default: "Next",
    },
    cancelText: {
      type: String,
      default: "",
    },
    skipText: {
      type: String,
      default: "Skip",
    },
    okBtnDisable: {
      type: Boolean,
      default: false,
    },
    next: String,
    back: String,
    skip: String,
    disableNext: Boolean,
    disableBack: Boolean,
    disableSkip: Number,
    isMobile: Boolean,
    query: {
      type: Object,
      required: false,
    },
    isLoadingApi: {
      type: Boolean,
      default: false,
    },
    customClasses: {
      type: String,
      default: '',
    },
    isCarInfoPage: {
      type: Boolean,
      default: false,
    },
    isHavingUploadIssue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      curStep: (state) => state.common.step,
    }),
  },
  methods: {
    onOk() {
      this.$emit("onOk");
      if (this.next) {
        this.query ? this.$router.push({ name: this.next, query: this.query }) : this.$router.push({ name: this.next });
      }
    },
    onCancel() {
      this.$emit("onCancel");
      if (this.back) {
        this.query ? this.$router.push({ name: this.back, query: this.query }) : this.$router.push({ name: this.back });
      }
    },
    onSkip() {
      this.$emit("onSkip");
      if (this.skip) {
        this.query ? this.$router.push({ name: this.skip, query: this.query }) : this.$router.push({ name: this.skip });
      }
    },
  },
};
</script>
<style lang="scss">
$arrowBorderSize: 3px;
$arrowSize: 10px;
$left: 135deg;
$right: 315deg;
$buttonMaxWidth: 60px;
.btn-group-wrapper {
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  // width: 100%;
  padding-bottom: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: row !important;
  justify-content: space-between;
  z-index: 101;
  .cancel-btn {
    flex: 1;
    max-width: $buttonMaxWidth;
    margin-right: 15px;
  }
  .skip-btn-wrapper {
    flex: 1;
    max-width: $buttonMaxWidth;
    margin-right: 15px;
    position: relative;
    .skip-btn {
      margin-bottom: 0;
      padding-left: 10px;
    }
  }
  &.skip-able {
    $buttonMaxWidth: 120px;
    .skip-btn-wrapper {
      max-width: $buttonMaxWidth;
    }
    .skip-btn::before {
      right: 20px;
    }
  }
}
.action-btn {
  position: relative;
  outline: none !important;
  width: 100%;
  height: 52px;
  padding: 16px 24px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  font-size: 16px;
  text-transform: uppercase;
  &::before {
    content: '';
    border-bottom-style: solid;
    border-bottom-width: $arrowBorderSize;
    border-right-style: solid;
    border-right-width: $arrowBorderSize;
    position: absolute;
    top: 39%;
    height: $arrowSize;
    width: $arrowSize;
  }
  &.left-arrow {
    &::before {
      left: 27px;
      transform: rotate($left);
    }
  }
  &.right-arrow {
    &::before {
      right: 30px;
      transform: rotate($right);
    }
  }
}
.cancel-btn {
  background-color: #ffffff;
  border: 1px solid #1F8252;
  color: #1F8252;
}
.skip-btn {
  background-color: #ffffff;
  border: 1px solid #1F8252;
  color: #1F8252;
  &:disabled {
    background-color: #ffffff !important;
    border-color: #b1b1b1 !important;
    color: #b1b1b1 !important;
  }
}
.skip-photo {
  &:hover {
    #tooltip {
      visibility: visible !important;
    }
  }
}
.skip-btn-cursor  {
  cursor: not-allowed !important;
}
.hide-tooltip {
  pointer-events: none !important;
}
.ok-btn {
  background-color: #1F8252;
  flex: 1;

  &:hover, &:active {
    background: linear-gradient(0deg, rgba(98, 0, 238, 0.08), rgba(98, 0, 238, 0.08)), #1F8252;
  }

  &.disabled {
    background-color: #C4C4C4;

    &:hover, &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #C4C4C4;
    }
  }
}

#tooltip {
  z-index: 1000;
  position: absolute;
  background: #2E312E;
  color: #ffffff;
  font-size: 12px;
  text-align: left;
  width: 245px;
  border-radius: 4px;
  padding: 6px 8px;
  visibility: hidden;
  bottom: 65px;
}

@media screen and(min-width: 768px) {
  $buttonMaxWidth: 79px;
  .btn-group-wrapper {
    padding-bottom: 20px;

    .cancel-btn {
      flex: 1;
      max-width: $buttonMaxWidth;
      margin-right: 20px;
    }
    .skip-btn-wrapper {
      flex: 1;
      max-width: $buttonMaxWidth;
      margin-right: 20px;
      position: relative;
      .skip-btn {
        margin-bottom: 0;
      }
    }
    &.skip-able {
      $buttonMaxWidth: 140px;
      .skip-btn-wrapper {
        max-width: $buttonMaxWidth;
      }
    }
    .left-arrow::before {
      left: 35px !important;
    }
    .right-arrow::before {
      right: 35px !important;
    }
  }
}
@media screen and(min-width: 900px) {
  .btn-group-wrapper {
    z-index: 100001;
  }
}
@media screen and(min-width: 1200px) {
  .btn-group-wrapper {
    max-width: 1120px;
    padding-left: 77px;
    padding-right: 77px;
  }
}
</style>